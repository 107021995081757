@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


html {
  scroll-behavior: smooth;
}

body[dir=rtl], body[dir=rtl] *:not(i) {
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
body[dir=ltr], body[dir=ltr] *:not(i) {
font-family: "Poppins", sans-serif;
}

body[dir=rtl] .lang-font {
  font-family: "Poppins", sans-serif !important;
}
body[dir=ltr] .lang-font {
  font-family: "IBM Plex Sans Arabic", sans-serif !important;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #CBD5E1;
}

*::-webkit-scrollbar-track:hover {
  background-color: #94A3B8;
}

*::-webkit-scrollbar-track:active {
  background-color: #64748B;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #5381B2;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #042254;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #042254;
}

.simpleScroll::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0) !important;
  background: #CBD5E1 padding-box !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
}
.simpleScroll::-webkit-scrollbar-thumb:hover {
  background: #94A3B8 padding-box !important;
}
.simpleScroll::-webkit-scrollbar {
  width: 13px !important;
  display: block !important;
  background: transparent !important;
}
.simpleScroll::-webkit-scrollbar-track,
.simpleScroll::-webkit-scrollbar-track:hover ,
.simpleScroll::-webkit-scrollbar-track:active {
  background: transparent !important;
}

.dot-flashing {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background-color: #fff;
  color: #fff;
  animation: dot-flashing .5s infinite linear alternate;
  animation-delay: 0.25s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -30px;
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background-color: #fff;
  color: #fff;
  animation: dot-flashing .5s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 30px;
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background-color: #fff;
  color: #fff;
  animation: dot-flashing .5s infinite alternate;
  animation-delay: .5s;
}

@keyframes dot-flashing {
  0% {
    background-color: #fff;
  }
  50%, 100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(150px * 5));
  }
}
@keyframes scroll2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 5));
  }
}
.auth_slider {
  align-items: center;
  display: flex;
  justify-content: center;
}
.slider {
  height: 134px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
/* .slider::before, .slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 134px;
  position: absolute;
  width: 134px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
} */
body[dir=rtl] .slider .slide-track {
  -webkit-animation: scroll 10s linear infinite;
          animation: scroll 10s linear infinite;
}
body[dir=ltr] .slider .slide-track {
  -webkit-animation: scroll2 10s linear infinite;
          animation: scroll2 10s linear infinite;
}
.slider .slide-track {
  display: flex;
  width: calc(134 * 15);
}
.slider .slide {
  height: 134px;
  width: 134px;
}
.swiper_box .swiper-pagination-bullet,
.swiper_box .swiper-pagination-bullet-active {
  border-radius: 0 !important;
  height: 6px !important;
  background: #fff !important;
}
.swiper_box .swiper-pagination-bullet {
  opacity: 0.4 !important;
  width: 24px !important;
}
.swiper_box .swiper-pagination-bullet-active {
  opacity: 1 !important;
  width: 56px !important;
}

.swiper_box .swiper-button-prev,
.swiper_box .swiper-button-next {
  width: 24px !important;
  height: 24px !important;
  top: 55% !important;
}
.swiper_box .swiper-button-prev:after, 
.swiper_box .swiper-button-next:after {
  font-size: 22px !important;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}
.leaflet-control-zoom {
  margin-top: 80px !important;
  margin-left: 18px !important;
}

/* react select */
.MaxHSelect.multiSelect__menu, .MaxHSelect.multiSelect__menu * {
  max-height: 80px !important;
}
.multiSelect__control {
  border-radius: 8px !important;
  line-height: 34px !important;
  border: rgb(203,213,225) 1px solid !important;
  box-shadow: none !important;
}
.multiSelect__control.multiSelect__control--is-focused {
  border: rgb(194, 232, 255) 1px solid !important;
}
.multiSelect__value-container {
  padding: 2px 12px !important;
}
.multiSelect__indicator-separator {
  display: none !important;
}
.multiSelect__indicator.multiSelect__dropdown-indicator svg {
  display:  none !important;
}
.multiSelect__indicator.multiSelect__dropdown-indicator {
  background: url(../public/assets/images/select-arrow.svg) !important;
  background-position: 15px 50% !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 40px !important;
}
.multiSelect__input-container input, .multiSelect__placeholder {
  color: rgb(71,85,105) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.multiSelect__option.multiSelect__option--is-focused {
  background: #C2E8FF !important;
}
.multiSelect__option.multiSelect__option--is-selected {
    background: #5381B2 !important;
}
.multiSelect__multi-value {
  border-radius: 8px !important;
  background: #F1F5F9 !important;
  height: 32px !important;
  line-height: 32px !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-inline-end: 12px !important;
}
.multiSelect__multi-value__label {
  padding: 0 !important;
  padding-inline-start: 8px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #042254 !important;
}
.multiSelect__multi-value__remove svg {
  display: none !important;
}
.multiSelect__multi-value__remove {
  padding: 0 !important;
  padding-inline-end: 24px !important;
  background: url(../public/assets/images/close-select.svg) center no-repeat !important;
}
.multiSelect__multi-value__remove:hover {
  background-color: none !important;
}
.multiSelect__indicator.multiSelect__clear-indicator {
  background: url(../public/assets/images/close-select.svg) center no-repeat !important;
}
.multiSelect__indicator.multiSelect__clear-indicator svg {
  display: none !important;
}